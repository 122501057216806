<!-- 
<div class="jumbotron">
 
<div class="text-center">
<a href="https://jsonworld.com"><h2>JSON WORLD</h2></a>
<a href="" target="_blank"><span>Server Side Pagination in Angular using ngx-pagination</span></a>
</div>
<div class="list">
<ul>
<li *ngFor="let item of collection | paginate: config">{{ item }}</li>
</ul>
<pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
</div>
</div>
 -->
<router-outlet></router-outlet>
<!-- 
<a [routerLink]="['/dashboard-2']" > dashboard-2 </a>

<br>
<a [routerLink]="['/deposit-transactions']" > Deposit Transactions </a> -->