import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from './user';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  user: User;
  apiServer = environment.apiUrl;

  constructor(private http: HttpClient, private datepipe: DatePipe) {
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    if (loggedUser != null) {
      this.user = loggedUser;
    }
  }

  isAuthenticated() {
    return this.user.token != null;
  }

  logInUser(email: string, password: string) {
    const body = {email: email, password: password};
    return this.http.post(this.apiServer + '/auth/login', body);
  }

  logOut() {
    localStorage.removeItem('user');
    return this.http.post(this.apiServer + '/auth/logout', {token: this.user.token});
    this.user = null;
  }

  getData(url: string, queryParams?: any) {
    let headersN = new HttpHeaders().set('Content-Type', 'application/json');
    headersN = headersN.set('Authorization', 'Bearer ' + this.user.token);
    return this.http.get(this.apiServer + url, {headers: headersN, params: queryParams});
  }

  postData(url: string, paramData?: any) {
    let headersN = new HttpHeaders().set('Content-Type', 'application/json');
    headersN = headersN.set('Authorization', 'Bearer ' + this.user.token);
    return this.http.post(this.apiServer + url, JSON.stringify(paramData), {headers: headersN});
  }

  formatDate(dt) {
    return this.datepipe.transform(new Date(dt), 'yyyy-MM-dd');
  }
}
